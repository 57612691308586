.items-to-compare-panel {
  width: 100%;
  position: fixed;
  background: #1d252cd9;
  z-index: 500;
  bottom: 0;
  height: 150px;
  padding: 10px 20px;

  .compare-row {
    display: flex;
    justify-content: space-between;
    color: white;
    margin-bottom: 7px;

    .compare-title {
      display: flex;
      align-items: center;
    }

    .compare-actions {
      display: flex;
      align-items: center;

      span {
        color: yellow;
        // text-decoration: underline;
        cursor: pointer;
      }

      button {
        margin-left: 10px;
      }
    }
  }

  .items-to-compare {
    display: flex;
    justify-content: center;
  }
}
