@import 'src/common.scss';

.selection {
  width: 220px;
  height: 90px;
  margin-right: 25px;
  position: relative;

  &.filled-selection {
    background-color: white;
  }

  &.empty-selection {
    background-color: #1d252c;
  }

  svg {
    position: absolute;
    color: $blue;
    background: white;
    border-radius: 50%;
    font-size: 23px;
    top: -10%;
    left: 95%;
    cursor: pointer;
  }

  .rows {
    display: flex;
    align-items: center;

    img {
      padding-left: 8px;
      width: 35%;
      height: 75%;
    }

    .text {
      justify-content: space-between;
      height: 100%;
      display: grid;
      padding: 0 8px;

      .item-name {
        padding-top: 5px;
        font-size: 14px;
        color: $blue;
        height: 45px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }

      .item-price {
        align-items: end;
        display: flex;
        padding-bottom: 5px;
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
}
