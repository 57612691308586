@import "src/common.scss";

$item-height: 150px;

.grid-card-item {
  height: 100%;

  .card-body {
    height: 100%;

    .card-inner-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;

      .price-and-retailer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 4px;
        height: 60px;
      }

      .grid-retailer-logo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;

        > img {
          max-width: 100px;
          max-height: 40px;
        }
      }
    }
  }
}

.grid-card-item .img-wrapper {
  position: relative;
  width: 100%;
  height: 160px;
  padding: 4px 4px 10px 4px;

  .card-img {
    display: block;
    margin: 0 auto;
    width: auto;
    max-width: 100%;
    max-height: 100%;
  }
}

.card-more-details {
  display: flex;
  flex-direction: row-reverse;
  // white-space:nowrap ;

  gap: 10px;
  background-color: #ffffffef;
  padding: 2% 0%;
  opacity: 1;
  transition: all 300ms ease-in-out;
  width: 100%;
  /* .section-1{
    padding-left:10px ;
  } */
  .card-text {
    font-weight: 600;
    color: #000;
    display: flex;
    gap: 5px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
}

// .grid-card-item .img-wrapper:hover {
//   .card-more-details {
//     opacity: 1;
//   }
// }

.items-list .card {
  .img-wrapper {
    width: 100%;
    height: 100%;
    max-height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;

    .card-img {
      display: block;
      margin: 0;
      max-width: 120px;
      max-height: 120px;
    }
  }
}

.card {
  border: 1px solid #dadce0;
  border-radius: 8px;
  margin-bottom: 14px;
  margin-right: 3px;
  .card-body{
    padding: 10px 12px;
  }

  .card-text{
    font-size: 0.8rem !important;
    font-weight: 600;
    color: #000;
  }

  span
  .card-image{
	padding: 5px;
	max-height: 175px !important;
	max-width: 175px;
  }
  
  .border-top{
     border-top: 1px solid #999;
  }
  
  .button-link{
	font-size: 0.8rem !important;
	padding: 0 !important;
	font-weight: 600 !important;
	text-align: left;
  }
  
  .btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none;
 }  
  
  &.mobile-card {
    // height: 210px;
  }

  .badge {
    position: absolute;
    top: 10px;
    background: linear-gradient(258.04deg, #e64500 38.33%, #f9a323 106.88%);
    border-radius: 0 3px 3px 0;
  }

.media {
  display: flex;
  align-items: center;
  padding-right: 0 !important;

  .card-body {
	// flex: unset;
	padding: 0;
	// height: 100%;
	// width: 100%;
	align-items: center;
	display: flex;
	justify-content: center;

	&.block {
	  display: block;
	}

	.media-price {
	  display: flex;
	  justify-content: center;
	  font-size: 14px !important;

	  p {
		font-size: 14px !important;
	  }
	}

	.card-image-wrapper {
	  display: flex;
	  justify-content: center;
	  max-height: 100%;
	  cursor: pointer;

	  &.small-card-image-wrapper {
		height: 50%;

		.card-img {
		  // width: auto;
		  // height: 100%;
		}
	  }

	  .card-img {
		// Row height
		// max-height: $item-height;
	  }
	}

	.media-retailer-logo {
	  text-align: center;

	  .retailer-logo {
      max-width: 130px;
	  }
	}
  }
}

    .description {
      align-items: center;
      padding-left: 0 !important;
      padding-right: 0 !important;

      .card-body {
        padding: 11px 1rem;

        .card-title {
          color: $dark-gray;
          font-weight: 400;
          font-size: 15px;
          cursor: pointer;
        }

        .item-details {
          display: flex;

          .item-details-column {
            margin-right: 20px;

            .card-text {
              margin: 0;
              font-size: 14px;
              color: $dark-gray;
            }
          }
        }

        .description-compare-and-show-details {
          color: $dark-gray;
          position: absolute;
          bottom: 0;
          margin-bottom: 5px;
          display: flex;

          .form-check {
            .form-check-label {
              text-decoration: underline;
              cursor: pointer;
            }
          }

          button {
            margin-left: 10px;
            padding: 0;
            color: $dark-gray;
          }
        }
      }
    }

    .small-description {
      align-items: start;

      .card-body {
        padding: 0 1rem;

        .card-title {
          font-size: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .item-details {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
  } /* card class ends */
  
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px !important;

	.actions-price {
	  display: flex;
	  align-items: center;
	  height: 100%;
	  cursor: pointer;
	}

	.actions-retailer-logo {
	  text-align: center;
	}

	//.actions-show-details {
	//}
  }

    .actions-compare {
      color: $dark-gray;
      white-space: nowrap;

        .form-check-label {
          cursor: pointer;
          font-weight: 600 !important;
          padding-left: 2px;
          text-decoration: underline;
        }
        
		.form-check-input{
            vertical-align: text-bottom;
        }
    }

  .retailer-logo {
	  max-height: 80px;
  }
  
  .logo-container {
    img{
      width: 100%;
      max-height: 50px;
      max-width: 150px;
      height: 50px;
      object-fit: contain;
    }
    @media (max-width: 900px) {
      .img-fluid {
        max-width: 50%;
      }
    }
  }
   
  .retailer-logo-parent-xs {
    //  display: flex;
     justify-content: center;
  }
  
  .retailer-logo-parent-md {
    display: flex;
    justify-content: center;
 }
  
  // Global for the whole card
  .prices-wrapper {
	text-align: center;
	
	/* padding: 0.2em; - causes column to extend container */
	
    .final-price {
      display: inline-block;
      color: $red;
      font-weight: 900; /* increasing from 600 to 900 due to font change to Jura */
      font-size: 1.1em !important; /* increasing due to font change to Jura */
	  white-space: nowrap;
    }

    .old-price {
      display: inline-block;
      color: $blue;
      font-weight: 900; /* increasing from 600 to 900 due to font change to Jura */
      font-size: 1.1em !important; /* increasing due to font change to Jura */
	  white-space: nowrap;
    }
    
	.strike-price{
       text-decoration: line-through;
    }
  }

  //   .heart-icon {
  //     font-size: 25px;

  //     &.favorited {
  //       color: $red;
  //     }
  //   }

  .disclaimer{
    background: transparent;
    border: none;
  }

  .fastyle{
    height: 13px !important;
    width: 13px !important;
  }