@import "src/common.scss";

.home {
  //color: white;
  display: -webkit-box;
  margin-top: $header-height;

  .home-content {
    width: 100%;
    margin: 0 auto;
    padding-left: $sidebar-width;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-height: calc(100vh - $header-height);

    @media (max-width: 992px) {
      padding-left: 0;
    }
  }
}
