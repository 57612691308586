@import 'src/common.scss';

.no-item-found-container {
  display: flex;
  flex-direction: column;
  font-family: Jura, Verdana, sans-serif;


  /* min-height: calc(100vh - 300px) !important;*/

  &.xl-no-item-found-container {
    /* width: calc(100% - 270px); */
    width:100%;
  }

  &.lg-no-item-found-container {
    /* width: calc(100% - 240px); */
    width:100%;
  }

  &.small-no-item-found-container {
    width: 100%;
    // height: calc(100vh - 130px) !important;
  }

  .mesg{
	font-size:1.2rem !important;
  }
  .no-item-found-content {
    /*align-items: center;
    justify-content: center;
    text-align-last: center;*/
    padding: 0 7px;

    &.small-font-size {
      /* font-size: 12px;*/
    }

    .top-text,
    .bottom-text {
      text-align: center;
    }

    .top-text {	
      color: $blue;
	  padding: 5px;
    }
	.top-text p {
		color: $dark-gray;
		margin-top: 15px !important;
		text-align-last: left;		
	}
	.top-text > ul {
		color: $dark-gray;
		text-align-last: left !important;
		  list-style-type: none !important;
		  margin: 0;
		  padding: 0;
		  text-align: left !important;
    }	
    .bottom-text {
      color: $blue;
      font-weight: 500;
	  font-size: 18px;
    }
	
	@media (max-width: 992px) {  
	  .top-text h1 {
			font-size:1.4rem !important;
	  } 
	  .top-text p, .top-text > li {
		font-size:1.2rem !important;
	  } 
	  
		img {
		  width: 100%;
		  max-width: 250px;
		  height: auto;
		}	  

        /* No animation for small screens */
        div.parent {
            font-family: Jura, Verdana, sans-serif;
            text-align: center;
            color: #006600;
            font-weight: 600;
            /* padding-top: 40vh;  */
            overflow: hidden;
        }

        div.first{
            font-family: Jura, Verdana, sans-serif;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            padding: 5px 0px;
        }

        div.last{

            margin: 0px !important;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            padding: 15px 0px;
        }
        div.last h1{
            padding: 0px !important;
            margin: 0px !important;
            font-family: Jura, Verdana, sans-serif;
            font-size: 20px !important;
            font-weight: 600;
            text-wrap: wrap;
        }

	}

	@media (min-width: 992px) {  
	  .top-text h1 {
		font-size:1.8rem !important;
	  } 
	  .top-text p, .top-text > li {
		font-size:1.4rem !important;
	  } 

		img {
		  width: 100%;
		  max-width: 375px;
		  height: auto;
		}

        div.parent {
            font-family: Jura, Verdana, sans-serif;
            text-align: center;
            color: #006600;
            font-weight: 600;
            /* padding-top: 40vh;  */
            overflow: hidden;
        }

        div.first{
            font-family: Jura, Verdana, sans-serif;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            padding: 25px 0px;
            animation: appear 4s 1;
        }

        div.last{

            margin: 0px !important;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
            padding: 25px 0px;
            animation: reveal 4s 1;
        }
        div.last h1{
            padding: 0px !important;
            margin: 0px !important;
            font-family: Jura, Verdana, sans-serif;
            font-size: 28px !important;
            font-weight: 600;
            animation: slide 4s 1;
        }
	}
	
  }
}

@keyframes appear{
    0%{opacity: 0;}
    20%{opacity: 1;}
    80%{opacity: 1;}
    100%{opacity: 0;}
}


div.search-detail{
    color: #dc3545;
    font-family: Jura, Verdana, sans-serif;
    font-size: 130%;
    font-weight: 700;
    text-align: center;
    /* animation: appear 9s infinite; */
}

div.why{
    font-family: Jura, Verdana, sans-serif;
    font-size: 100%;
    font-weight: 600;
    text-align: center;
    /* animation: appear 9s infinite; */
}
div.why > ul {
    display: inline-block;
    text-align: left;
    list-style: none;
}
div.why li {
padding-top: 9px;
}
div.cta-btn{
    font-size: 100% !important;
    font-weight: 400;
    text-align: center;
}

@keyframes slide{
    0%{margin-left:-350px;}
    20%{margin-left:-350px;}
    35%{margin-left:0px;}
    100%{margin-left:0px;}
}
@keyframes reveal{
    0%{opacity: 0; width: 0px;}
    30%{opacity: 1; width: 0px;}
    100%{opacity: 1; width: 600px;} /* adjust this to fit span text correctly */
}

.no-result-found{
	// position: fixed;
	color: orangered !important;
	font-size: 14px;
	padding-top: 10px;
}

.notfound-container{
	width: 100%;
	/*text-align: center;
	justify-content: center;*/
	display: flex;
  flex-direction: column;
}
/* dupe in custom.css */
.brand_disclaimer{
    font-family: Jura,Verdana,sans-serif;
    font-size: 12px;
    font-weight: 300;
    padding: 4px;
}
