@import "src/common.scss";

.item-column {
  color: $dark-gray;
  text-align: center;
  font-size: 14px;

  .item-image {
    width: 100%;
    padding: 10px 0 0;
    cursor: pointer;
   
    img {
      height: 100px;
      object-fit: contain;
      width: 120px;
    }
  }

  .item-price {
    cursor: pointer;
    padding: 15px 30px 0;

    // 992px is the tablet breakpoint
    @media (max-width: 992px) {
      padding: 15px 10px 0;
    }

    .prices-wrapper {
      display: flex;
    }
  }

  .item-name {
    height: 20px;
    height: 75px; // 100px;
    text-align: start;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: initial;
    display: -webkit-box;
    // -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: $dark-gray;
    cursor: pointer;
    padding: 10px 30px 20px;

    @media (max-width: 992px) {
      padding: 15px 10px 0;
    }
  }

  .retailer-logo {
    max-width: calc(100% - 60px);
    height: 50px;
    cursor: pointer;
  }

  .item-compare-detail {

    .value{
        height: 70px;
        align-items: center;
        display: flex;
        justify-content: center;
        border-bottom: 1px solid #e3e3e3;
        position: relative;
    }

    .sub-header {
      position: absolute;
      top: 10px;
      font-weight: 500;
      font-size: 13px;
      left: 30px;
	    color: $blue;

      @media (max-width: 992px) {
        left: 10px;
      }
    }
  }

  .item-detail-table {
    margin-bottom: unset;

    .item-detail-first-columnn {
      color: $dark-gray;
      text-align: start;
      padding-left: 10px;
      width: 25%;
      font-weight: 600;
    }

    .item-detail-second-columnn {
      color: $blue;
      text-align: start;
    }
  }
}