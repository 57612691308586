@import 'src/common.scss';

.custom-navbar {
  padding-right: 0px !important;
  .navbar {
    border-bottom: 1px solid #d9d9d9;
    justify-content: space-between;
    padding: 10px 10px;
    height: $header-height;
    box-shadow: 0 1px 6px 0 #d9d9d9;
    // &.xl-navbar {
    //   padding: 10px 23px;
    // }

    // &.lg-navbar {
    //   padding: 10px 23px;
    // }
    svg{
      height:24px!important;
      width:24px!important;
    }

    .navbar-toggler {
      color: $blue;
      border: none;

      .dropdown {
        .dropdown-toggle {
          &::after {
            display: none;
          }
        }

        .dropdown-menu {
          border-radius: 0;

          .dropdown-item {
            padding: 7px 1rem;
            color: $blue;
            font-family: Jura, Verdana, sans-serif;
            font-weight: 700;
            font-size: 15px;
          }
        }
      }
    }

    &.small-navbar {
      padding: 6px 12px;

      .navbar-toggler {
        padding: 0;
        margin-right: 0 !important;
      }

      .flex-container {
        justify-content: center;
		width: 100%;
      }
    }

    .flex-container {
      display: flex;
      justify-content: space-between; /* keeps child elements evenly spaced */
      width: 100%;
      //   &:not(:first-child) {
      //     width: -webkit-fill-available;
      //   }

      &.large {
        width: 80%;
        padding: 0px 10px 0px 10px;
      }

      button {
        margin: 0 !important;
      }
	  
      /*
	  @media (max-width: 544px) { 
         button.navbar-toggler {
         }
      }
	  */

      .navbar-brand {
        margin-right: 0px;
        margin-left: 0px;
      }

      .navbar-collapse {
        .routes-container {

          .navbar-nav {
            a {
              text-decoration: none;
              text-align: center;
              font-family: Jura, Verdana, sans-serif;
              font-weight: 700;
              font-size: 15px;
              line-height: 17px;
              color: $light-blue;
              padding: 8px 0 !important;
              margin: 0 15px;
              white-space: nowrap;

              /* &.active {
                color: $blue !important;
                border-bottom: 1px solid $blue;
              } */
            }

			a:hover {
				color: $light-blue !important;
				text-decoration: underline;
				/* border-bottom: 1px solid $blue; */
			}			  
          }

          &.lg-routes {
            margin-left:0px;
            margin-right:0px;
          }

          &.small-routes {
            .navbar-nav {
              a {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }

  .searcher-underheader {
    display: flex;
    flex-direction: initial;
    padding: 0.5rem;
    color: $blue;

    .navbar-toggler {
      margin-right: 0 !important;
    }

    .input-group {
      margin: 0 15px;
    }
	
    @keyframes glowing {
	  0% {
         background-color: #2ba805;
         box-shadow: 0 0 5px #2ba805;
      }
      
	  50% {
         background-color: #ff0;
         box-shadow: 0 0 15px #ff0;
      }
	  
      100% {
         background-color: #2ba805;
         box-shadow: 0 0 5px #2ba805;
      }
    }

    #filter-icon {
      animation: glowing 1300ms 3;
    }	
  }
  
  .pwimage{
    max-width: 40px;
    height: auto;
  }
}
  
  