@import 'src/common.scss';

.compare-table {
  padding: 15px 20px 0;
  overflow: auto;
  &.xl-compare-table {
    width: calc(100% - $sidebar-width);
  }

  &.lg-compare-table {
    width: calc(100% - $sidebar-width);
  }

  &.small-compare-table {
    width: 100%;
  }

  .header {
    display: flex;
    color: $dark-gray;
    justify-content: space-between;
    font-size: medium;
    font-weight: 600 !important;
    .hcustom {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .compare-table-content {
    background-color: white;
    display: flex;
    box-shadow: #32325d40 0 2px 10px -1px, #0000004d 0 1px 3px -1px;
    margin-bottom: 20px;

    .col {
      padding: 0;
    }
  }
}

.final-price {
  color: $red;
  font-weight: 600;
  font-size: 17px !important;
  margin: 0 5px 0 0;
}

.old-price {
  color: $blue;
  font-weight: 500;
  font-size: 17px !important;
  margin: 0;
}
.strike-price{
   text-decoration: line-through;
}

//custome table css
.sticky-element {
  position: -webkit-sticky;
  position: sticky;
  top: -16px; 
  z-index: 999;
}


.custome_table{
    box-shadow: #32325d40 0 2px 10px -1px, #0000004d 0 1px 3px -1px;
    .spec_th  , .spec_td   {
      max-width: 200px;
      min-width: 200px;
    }
    thead tr::-webkit-scrollbar {
      display: none;
    }
    .product_td ,.product{
      flex: 1;
      min-width: 200px;
    }
  
    .product{
      font-size: 14px;
    }
    .product-image img{
      width: auto;
      height: 70px;
      object-fit: contain;
    }
    .product-name{
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2; 
      -webkit-box-orient: vertical;
      color: $dark-gray;
      text-align: start;
      font-weight: lighter !important;
    }
    .spec_td , .spec_th{
      position: sticky;
      left: 0;
      z-index: 99;
      background-color: white;
    }
    
}

.varient-2
{
  tbody{
    display: grid;
  }
}
.w-fitcontent{
  width: fit-content !important;
}
@media (max-width:768px){
  .product-2{
    width: fit-content !important;
  }
  .custome_table{
    
    font-size: 13px;
    .spec_th  , .spec_td   {
      max-width: 180px;
      min-width: 180px;
      
    }
    .product_td ,.product{
      min-width: 150px;
      text-wrap: wrap;
    }
    
    .product-image img{
      width: auto;
      height: 40px;
      object-fit: contain;
    }
    .product-name{
      font-weight: bold;
    }
    .spec_td , .spec_th{
      position: relative;
    }   
}
}