@import "src/common.scss";

.sidebar {
  //   position: absolute;
  //   top: 93px;
  //   bottom: 0;
  //   left: 0;
  //   z-index: 100;
  //   overflow-y: auto;

  height: calc(100vh - 60px);
  max-height: calc(100vh - 60px);
  overflow-x: auto;
  position: fixed;
  padding: 10px 8px;

  @media (min-width: 992px) {
    // to match with current breakpoint of large screen that changes the layout
    /* margin-top: 63px; */ // using value of $header-height to accomodate fixed-top custom-navbar
    border-right: 1px solid $darkest-gray;
  }
  &.xl-sidebar {
    width: $sidebar-width;
  }

  &.lg-sidebar {
    width: $sidebar-width;
  }

  &.small-sidebar {
    display: none;
    width: 100%;
    height: 100%;
    max-height: calc(100vh - 120px) !important;
  }
  .buttons-container {
    display: flex;
    justify-content: start;
    padding: 5px 5px 5px 110px;
    margin-bottom: 35px;
    background-color: $gray;

    :last-child {
      margin-left: 5px;
    }

    button {
      padding: 2px 7px;
      color: black;
      font-weight: 500;
      border-radius: 5px;
      box-shadow: 0 2px 5px $gray !important;
      border-color: $blue !important;
      background-color: white !important;

      &:hover {
        background-color: #f2f2f2ba !important;
      }

      &:active {
        background-color: #cececeba !important;
      }
    }
  }

  .accordion {
    // max-height: 100%;
    padding:8px; /* should match with the Clear Filters link above the accordion */
    .accordion-item {
      border: 1px solid #dadce0;
      border-radius: 8px;
      margin-bottom: 8px;
      padding: 3px;
      font-family: Jura, Verdana, sans-serif;
      font-weight: 700;
      font-size: 18px;
      color: $dark-gray;

      .accordion-header {
        display: flex;
        align-items: center;

        .accordion-button {
          background-color: transparent;
          box-shadow: none;
          font-size: 14px;
          font-weight: 700;
          padding: 6px 6px 6px 10px;

          &::after {
            background-size: 14px;
            width: 14px;
          }

          &:not(.collapsed) {
            color: black;
          }

          p {
            background: $blue;
            color: $blue;
            margin: 0;
            margin-right: 5px;
            justify-content: center;
            display: flex;
            width: 2px;
          }
        }
      }

      .accordion-body {
        // padding-left: 25px;
        padding: 0 14px 6px 14px;

        .range-inputs {
          margin-top: -23px;
          justify-content: space-between;
        }

        .checkbox-filter {
          justify-content: space-between;
          color: $dark-gray;
          margin-bottom: -2px;
          padding: 2px;

          .form-check {
            font-size: 13px;
            white-space: nowrap;
          }

          label {
            font-size: 14px !important;
          }

          p {
            margin: 0;
            padding-right: 5px;
            color: $dark-gray;
            font-size: 13px;
            font-weight: 600;
          }
        }
        
        //range css
        .filter-range input[type=number] {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: textfield;
        }
      }
    }
  }
}

.sidebar {
  --sb-track-color: #00000010;
  --sb-thumb-color: #404040;
  // --sb-size: 6px;
  scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
  overflow-y: auto;  
  scrollbar-width: thin;
  scrollbar-gutter: stable;
}

.sidebar::-webkit-scrollbar {
  width: var(--sb-size);
}

.sidebar::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 1px;
}

.sidebar::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 1px;
}

.sidebar:hover {
  overflow-y: auto;
}

.link{
  font-size: 15px;
  font-weight: 600;
  font-family: Jura, Verdana, sans-serif;
  white-space: nowrap;
  padding: 2px 2px 2px 10px;
}

.btn:focus,.btn:active {
  outline: none !important;
  box-shadow: none;
} 


//minmax input
.input-wrapper{
  font-size: 14px;
}