@import 'src/common.scss';

body {
  margin: 0;
  background: $pw-bg-color;
  padding-right: 0px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  button {
    svg {
      height: 24px !important;
      width: 24px !important;
    }
  }

  // This component is placed inside CustomNavbar.tsx - this is the sidebar container
  .offcanvas-start {
    max-width: 70% !important;
    margin-top: $header-height !important;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background: #2388ed;
    }

     .offcanvas-header {
      // justify-content: end;
      padding: 0.3rem 0.5rem;
	  border-bottom: 1px  solid $gray !important;
    }
	.offcanvas-header .offcanvas-title{
		color: $blue !important;
		font-size: medium;
		text-decoration: underline;
	}
    .offcanvas-body {
      padding: 2px 2px !important;

      background-color: $pw-bg-color;
      .sidebar {
        position: absolute;
        display: block !important;
      }
    }
  }
  .offcanvas-backdrop {
    opacity: 0 !important;
  }
}

.react-tooltip{
  z-index: 1000;
}
.prefixUnits{
  font-size: 13px;
}
@media print {
  body * {
    visibility: hidden;
  }

  /* Control visibility of print content */
  .print-area, .print-area * {
    visibility: visible;
  }

  /* Set positioning and layout for print-area */
  .print-area {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    transform: scale(0.8); /* Adjust scale as needed */
    transform-origin: top center;

    .print-logo{
      display: flex !important;

      img{
        width: 300px;
        height: auto;
        object-fit: contain;
      }
    }
    
    table{
      tbody{
        tr{
          background-color: red !important;
        }
      }
    }
  }
  *::-webkit-scrollbar {
    display: none;
  }
  @page {
    size: A3; /* Ensure it's set to A4 or a standard size */
    margin: 0;
    padding: 0;
  }

  /* Prevent page breaks within the content */
  .print-area {
    page-break-inside: avoid;
  }

  /* If you have a header or footer that you want to show */
  header, footer {
    display: none;
  }
  /* Adjust page margins for printing */
  @page {
    margin: 0;
    padding: 0;
  }
}