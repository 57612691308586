@import "src/common.scss";

.items-list-container {
  min-height: calc(100vh - 77px);
  max-height: calc(100vh - 77px);
  overflow: auto;
  &.xl-items-list-container {
    width: 100%;
  }

  &.lg-items-list-container {
    width: 100%;
  }

  &.small-items-list-container {
    width: 100%;
  }

  .item-list-controls {
    display: flex;
    padding-right: 18px;
    align-items: center;
    justify-content: space-between;
  }

  .items-sort {
    display: flex;
    justify-content: space-between;
    padding: 5px 13px;

    .list-view-type {
      font-size: x-large;
      display: flex;
      align-items: center;

      :first-child {
        margin: 0;
        //   margin-right: 5px;
      }

      .selected {
        color: black;
      }
    }
    .terms {
      font-size: small;
    }
    .dropdown-wrapper {
      display: flex;
      align-items: center;
      color: black;
      font-size: 15px;
      font-weight: 600;

      .dropdown {
        button {
          background-color: white;
          color: black;
          border: 1px solid #dadce0;
          border-radius: 8px;
          margin-left: 5px;
          padding: 3px 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 15px;
          font-weight: 600;
          width: 250px;

          &:focus,
          &:hover {
            border: 1px solid #bfbfbf !important;
          }

          .sort-by {
            margin-right: 5px;
            font-weight: 600;
          }
        }

        .dropdown-menu {
          border-radius: 0;
          width: 250px;
          //   .dropdown-item {
          //     padding: 0.5rem 1rem;
          //   }
        }
      }
    }
  }

  .list-view-type-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;

      @media (max-width: 425px) {
      .list-view-type-button {
      display: none;
      }
    }

    .list-view-type-button {
      cursor: pointer;
      width: 32px;
      height: 32px;
      padding: 3px;
      border: 1px solid #bfbfbf;

      &.active {
        border: 1px solid #2388ed;

        > * {
          color: #2388ed;
          fill: #2388ed;
          stroke: #2388ed;
        }
      }

      > * {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .items-list {
    padding: 0px 8px 8px 8px ;
  }

  .items-grid {
    padding: 15px;
    padding-top: 0;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 16px;
  }



  @media (max-width: 1800px) {
    .items-grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  @media (max-width: 1400px) {
    .items-grid {
      grid-template-columns: 1fr 1fr !important;
    }
  }

  @media (max-width: 768px) {
    .items-grid {
      grid-template-columns: 1fr !important;
    }
  }

  .items-pagination {
    display: block;
    justify-content: end;

    .pagination {
      .page-item {
        // Text
        --bs-pagination-color: #84849d;
        --bs-pagination-hover-color: #84849d;
        --bs-pagination-focus-color: #84849d;
        --bs-pagination-active-color: #84849d;

        //bg
        --bs-pagination-border-color: transparent;
        --bs-pagination-hover-bg: transparent;
        --bs-pagination-hover-border-color: transparent;
        --bs-pagination-focus-bg: transparent;
        --bs-pagination-focus-box-shadow: transparent;
        --bs-pagination-active-bg: transparent;
        --bs-pagination-active-border-color: #2388ed;

        .page-link {
          border-radius: 3px;
          color: #000;

          //   span {
          //     font-size: 33px;
          //   }
        }
      }
    }
  }
}

.message {
    color: #dc143c;
    padding: 2px 6px;
    font-weight: 600;

    @media (min-width: 544px) {
        font-size: 1.0em;
    }

    @media (max-width: 544px) {
        font-size: 0.9em;
    }
}