@import "src/common.scss";

.modal {
  padding-left: 0 !important;
  scrollbar-width: thin;
  // overflow: hidden;
  .modal-dialog {
    height: -webkit-fill-available;
    margin-right: auto;
    margin-left: auto;
    max-width: 90vw;
    

    .modal-content {
      border: 1px solid $blue;
      padding: 0 15px;

      overflow: auto;
      .modal-header {
        .modal-title {
          font-size: 16px;
        }
      }

      .modal-body {
    
        margin-right:-15px;
        scrollbar-width: thin;
        .item-column div{
          width: 100%;
        }
      }

      .item-column {
        display: flex;
        gap: 10px;

        .item-image {
          padding-top: 30px;

          img {
            width: 100%;
            max-width: 250px;
            height: auto;
          }
        }

        .item-price {
          padding: 10px 10px 0;
        }

        .item-name {
          padding: 0 10px;
          margin-bottom: 10px;
          height: auto;
          font-weight: 600;
          // -webkit-line-clamp: 3;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .modal {
    .modal-dialog {
      .modal-content {
        .item-column {
          flex-direction: column;
        }
      }
    }
  }
  
}