@import 'src/common.scss';

.input-group {
  border-radius: 20px;
  border: 1px solid #dadce0;

  @media (min-width: 992px) {
    margin: 0 17% !important;
    width: 65%;
  }

  @media (max-width: 992px) {
    margin: 0 10% !important;
    width: 80%;
  }

  button {
    display: flex;
    align-items: center;
    border-radius: 20px;
    border: none;
    background-color: white;
    box-shadow: none;
    color: $gray;

    &:focus,
    &:hover,
    &:active,
    &.show {
      color: $gray;
      background: transparent;
    }
  }

  input {
    border: none;
    border-radius: 20px;

    &:focus {
      box-shadow: none;
    }
  }
}

.new-searchbar{
  min-height: 36px;
  height: 36px;
}