@font-face {
    font-family: "Jura";
    src: url("./Jura-VariableFont_wght.ttf");
}

body{
    font-family: Jura, Verdana, sans-serif;
}
$gray: #808080;
$dark-gray: #333333;
$darkest-gray: #1a1a1a;
$pw-bg-color:#f1f3f7;

/* these are logo blues */
$blue: #0c4e92;
$light-blue: #2388ed;
$light-blue-shadow: #2388ed79;
$lighter-blue: #4a9bde;

$red: #e60000;
$dark-red: #b70303;

$header-height: 77px;
$sidebar-width: 270px;
 
  .icon_container_parent{
    position: fixed;
    display: flex;
    flex-wrap: wrap-reverse;
	top: 70%;
	z-index: 50;
  }  
  .icon_container{
	padding: 2px;
    position: absolute;
	z-index: 51;
    background-color:#FFF;
	top: 50%;
	display:flex;
	align-items:center; 
	flex-direction:column; 
  }
  
  .icon_container > button {
    padding: 3px;
    margin: 5px;
    z-index: 52;
    background-color: #FFF !important;
    box-shadow: 5px 5px 5px gray;

  }
  .icon_container > button:active,
  .icon_container > button:focus{
	background-color: #FFF !important;
	box-shadow: 5px 5px 5px gray;
  }
  .icon_container > button:hover {
    background-color: #FFF !important;
	box-shadow: 5px 5px 5px $blue;
  }
  
  .faicon{
    color: $blue;
    size: 14px;
  }
  @media (max-width: 992px){
    .icon_container_parent{
      left: 43%;
    }
  }
  @media (min-width: 992px){
   .icon_container_parent{
     left: calc($sidebar-width - 60px); // sidebar width dependent
   }
  }
 
 .spinner {
    display: block;
    position: fixed;
    z-index: 999; /* High z-index so it is on top of the page */
    top: 50%;
    right: 50%; /* or: left: 50%; */
    margin-top: -30px; /* half of the elements height */
    margin-right: -30px; /* half of the elements width */
    color: black;
  }
.parentDisable{
  position: fixed;
  top: 0;
  left: 0;
  background:transparent;
  opacity: 0.8;
  z-index: 998;
  height: 100%;
  width: 100%;
}

.overlay-box {
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  color: white; background: #bfbfbf; opacity: .8;
  z-index: 9999;
}
.fastyle{
    color: $light-blue;
}
.ttclass{
    white-space: normal; // doesn't work
    max-width: 85% !important; /* this prevents tooltip going off screen */
}

.button{
 border: none;
 background: transparent;
 color:$darkest-gray;
}

.button-blue{
  background-color: $lighter-blue;
  transition: all 0.2s ease-in-out !important;
  // box-shadow: $light-blue 0px 16px 21px -12px;
  box-shadow: $light-blue-shadow 2.4px 2.4px 3.2px;
}
.button-blue:hover{
  background-color: #0c4f92;
}

.fs-14{
  font-size: 14px;
}
.cursor-pointer{
  cursor: pointer;
}

